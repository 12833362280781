import { useState } from "react";
import Icon from "@mdi/react";
import {
  mdiMagnify,
  mdiCheck,
  mdiTimerSand,
  mdiMessageQuestion,
  mdiRestart,
} from "@mdi/js";
import IntroductionService from "./IntroductionService";
import Stepper from "./Stepper";

/**
 * The Cozy Intro app.
 * @returns CozyIntros App
 */
export default function App() {
  /**
   * Define the Introduction Service that is the gateway to the backend.
   */
  const introductionService = new IntroductionService(
    "https://production-api.cozyintros.com"
  );

  /**
   * These Step are shown at the bottom of the screen
   * Note the minimum index is at what point do we change the number
   * To Check mark. So order matters in this variable, and order matters
   * in {@var ComponentState}
   */
  const steps = [
    {
      subtitle: "Enter the LinkedIn URL of the VIP you’d like to meet",
      minimumIndex: 4,
    },
    {
      subtitle: "Share why you'd like to meet",
      minimumIndex: 5,
    },
    {
      subtitle:
        "If we are in touch with the requested VIP, you’ll be prompted to pay $10 in order to send the VIP the request",
      minimumIndex: 10,
    },
    {
      subtitle: "Close this tab , go about your day and get Cozy!",
      minimumIndex: 10,
    },
    {
      subtitle:
        "If the VIP accepts the request, you’ll receive an email with further instructions",
      minimumIndex: 10,
    },
    {
      subtitle: "If the connection turns out to be valuable, let us know!",
      minimumIndex: 10,
    },
  ];

  /**
   * Interface defining the structure of our component state
   */
  interface ComponentState {
    inputClassName: string;
    buttonClassName: string;
    searchIconClassName: string;
    timerIconClassName: string;
    checkIconClassName: string;
    arrowIconClassName: string;
    placeHolderText: string;
    weCanConnectYouClassName: string;
    weCannotConnectYouClassName: string;
    restartIconClassName: string;
  }

  /**
   * Component State is how we control the Search Bars State
   * Instead of making everything if statements, we describe how the page
   * looks at different "checkpoints"
   */

  const ComponentStates: { [key: string]: ComponentState } = {
    INITIAL: {
      inputClassName: "w-20 h-20",
      buttonClassName: "opacity-1 bg-yellow-500 hover:bg-yellow-700",
      searchIconClassName: "opacity-1",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-0",
      placeHolderText: "Insert A Linkedin Profile Url Here",
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
    FAILURE_LINKEDIN: {
      inputClassName: "w-20 h-20",
      buttonClassName: "bg-red-500 hover:bg-red-600",
      searchIconClassName: "opacity-0",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-0",
      placeHolderText: "Insert A Linkedin Profile Url Here",
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-1",
      restartIconClassName: "opacity-1",
    },
    VALIDATING_LINKEDIN: {
      inputClassName: "h-20 pl-16 w-[400px]",
      buttonClassName:
        "translate-x-[160px] rotate-[360deg] bg-yellow-500 hover:bg-yellow-700",
      searchIconClassName: "opacity-0",
      timerIconClassName: "opacity-1",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-0",
      placeHolderText: "Insert A Linkedin Profile Url Here",
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
    PROMPT_LNKEDIN: {
      inputClassName: "h-20 pl-16 w-[400px]",
      buttonClassName:
        "translate-x-[160px] rotate-[360deg] bg-yellow-500 hover:bg-yellow-700",
      searchIconClassName: "opacity-1",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-0",
      placeHolderText: "Insert A Linkedin Profile Url Here",
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
    PROMPT_REASON: {
      inputClassName: "h-20 pl-16 w-[400px]",
      buttonClassName:
        "translate-x-[160px] rotate-[360deg] bg-emerald-500 hover:bg-emerald-700",
      searchIconClassName: "opacity-0",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-1",
      placeHolderText: "Why do you want to meet with this contact?",
      weCanConnectYouClassName: "opacity-1",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
    REDIRECT_TO_STRIPE: {
      inputClassName: "w-20 h-20",
      buttonClassName: "opacity-1 bg-emerald-500 hover:bg-emerald-700",
      searchIconClassName: "opacity-0",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-1",
      arrowIconClassName: "opacity-0",
      placeHolderText: "Insert A Linkedin Profile Url Here",
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
  };

  const [currentState, setCurrentState] = useState("INITIAL");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [inputTextBoxValue, setInputTextBoxValue] = useState("");
  const {
    inputClassName,
    buttonClassName,
    searchIconClassName,
    timerIconClassName,
    checkIconClassName,
    placeHolderText,
    arrowIconClassName,
    weCannotConnectYouClassName,
    weCanConnectYouClassName,
    restartIconClassName,
  } = ComponentStates[currentState];

  /**
   *
   * @returns
   */
  const handleClickV2 = async () => {
    // Any time we reset, or we are fresh, our next state is prompting the user
    // For linkedin
    if (currentState === "INITIAL" || currentState === "FAILURE_LINKEDIN") {
      setCurrentState("PROMPT_LNKEDIN");
      return;
    }

    // When we are submitting a linkedin profile
    // We want to ensure we don't submit empty stuff
    // If its good, we await a response from the server.
    // *Given the server responds with "yes", we want to allow the user*
    // *Then* clear the input box, so that they can share why they want to meet
    // *Given the server responds with "yes", we want to allow the user*
    // *Then* clear the input box, so that they can share why they want to meet
    if (currentState === "PROMPT_LNKEDIN") {
      if (!inputTextBoxValue || inputTextBoxValue.length === 0) {
        return;
      }
      setCurrentState("VALIDATING_LINKEDIN");

      introductionService
        .isConnectionPossible(inputTextBoxValue)
        .then((response) => {
          if (response === true) {
            setCurrentState("PROMPT_REASON");
            setLinkedInUrl(inputTextBoxValue);
          } else {
            setCurrentState("FAILURE_LINKEDIN");
          }
        })
        .catch((e) => setCurrentState("FAILURE_LINKEDIN"))
        .finally(() => setInputTextBoxValue(""));
      return;
    }

    if (currentState === "VALIDATING_LINKEDIN") {
      setInputTextBoxValue("");
      setCurrentState("PROMPT_REASON");
      return;
    }

    if (currentState === "PROMPT_REASON") {
      if (!inputTextBoxValue || inputTextBoxValue.length === 0) {
        return;
      }
      setCurrentState("REDIRECT_TO_STRIPE");
      const url = await introductionService.submitPotentialConnection(
        linkedInUrl,
        inputTextBoxValue
      );

      window.location.href = url;
      return;
    }
  };

  return (
    <div className="bg-indigo-950 min-h-screen flex flex-col items-center justify-center">
      {/* Header */}
      <div>
        <img src={"./logo.png"} alt="Logo" className="w-40 h-40 rounded-xl" />
      </div>
      <div className="mb-4 mt-4">
        <h1 className="text-5xl text-blue-300">Cozy Intros</h1>
      </div>

      <div className="relative h-20 sm:w-1/3 w-11/12 mx-auto mt-2">
        <div
          id="WeCanConnectYou"
          className={`${weCanConnectYouClassName} w-full transition-opacity duration-1000 delay-100 text-blue-800 bg-emerald-400  text-center py-4 rounded-lg shadow-lg absolute top-0 `}
        >
          Yes, we can connect you.
        </div>
        <div
          id="WeCannotConnectYou"
          className={`${weCannotConnectYouClassName} w-full transition-opacity duration-1000 delay-100 text-red-800 bg-red-400 text-center p-4 rounded-lg shadow-lg absolute top-0`}
        >
          Unfortunately, we cannot connect you.
        </div>
      </div>

      <div className="relative">
        {/* Input Bar */}
        <input
          type="text"
          className={`${inputClassName} text-sm bg-white border-4 text-blue-500 border-white rounded-full py-3 pl-8 pr-3 placeholder-gray-400 focus:outline-none focus:ring-5 focus:ring-yellow-600 transition-all duration-500`} // Move up by 400px if connectionResponse is not null or false
          placeholder={placeHolderText}
          value={inputTextBoxValue}
          onChange={(e) => setInputTextBoxValue(e.target.value)}
        />
        <button
          className={`${buttonClassName} w-14 h-14 absolute inset-0 m-auto rounded-full flex items-center justify-center shadow-lg transition-all duration-500 `}
          onClick={handleClickV2}
        >
          <Icon
            path={mdiRestart}
            size={1.5}
            className={`${restartIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full`}
          />
          <Icon
            path={mdiMagnify}
            size={1.5}
            className={`${searchIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full`}
          />

          <Icon
            path={mdiTimerSand}
            size={1.5}
            className={`${timerIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full icon-spin`}
          />

          <Icon
            path={mdiCheck}
            size={1.5}
            className={`${checkIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full`}
          />

          <Icon
            path={mdiMessageQuestion}
            size={1.5}
            className={`${arrowIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full icon-pulse-left`}
          />
          <Icon
            path={mdiCheck}
            size={1.5}
            className={`${checkIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full`}
          />
        </button>
      </div>

      <Stepper
        steps={steps}
        currentIndex={Object.keys(ComponentStates).indexOf(currentState)}
      />
    </div>
  );
}
