import { mdiCheckCircle } from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  currentIndex: number,
  steps: {
    title?: string;
    subtitle: string;
    minimumIndex: number;
  }[];
}

export default function Stepper({ steps, currentIndex }: Props) {
  return (
    <div className="pt-10">
      <ol className="w-3/4 mx-auto flex flex-col md:flex-row items-center text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base md:space-x-10 md:space-y-0 space-y-10 border-t-2 border-blue-300 pt-16">
        {steps.map((s, i) => (
          <li className="flex-1 flex flex-col items-center relative space-y-10 w-full" key={i}>
            {/* Number on top */}
            <div className="absolute -top-5 text-yellow-500 text-xl font-bold bg-blue-600 rounded-full w-10 h-10 flex items-center justify-center border border-blue-300">
              { currentIndex >= s.minimumIndex && ( 
                <Icon path={mdiCheckCircle}/>
              )}

{ currentIndex < s.minimumIndex && ( 
                <p>{i + 1}</p>
              )}
              
            </div>

            {/* Bordered element */}
            <div className="flex md:h-44 flex-col items-center justify-center w-full h-full min-h-32 text-blue-200 p-4 border-2 border-blue-300 rounded-lg mt-6">
              <div className="font-medium">{s.title}</div>
              <span className="text-sm text-blue-300 mt-1">{s.subtitle}</span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}
